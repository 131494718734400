import { client } from "../client";
import { UpdateCartItemParams } from "../../types";

export const updateCartItem = async (params: UpdateCartItemParams): Promise<any> => {
  const response = await client.post("/updateCartItem", params, {
    headers: {
      Accept: "application/json", // Specify Accept header
      "Content-Type": "application/json", // Also specify Content-Type if needed
    },
  });

  return response.data;
};
