<template>
  <component
    :is="tag"
    :size="size"
    :weight="weight"
    :align="align"
    :uppercase="uppercase"
    class="w-full"
    :class="[
      className,
      sizeClasses[size],
      weightClasses[weight],
      uppercase && 'ui-uppercase',
      align && `ui-text-${align}`,
      displayFont ? 'ui-font-display' : 'ui-font-sans',
    ]"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { TextProps } from "./types";

withDefaults(defineProps<TextProps>(), {
  tag: "p",
  size: "md",
  weight: "normal",
  uppercase: false,
  displayFont: false,
});

const sizeClasses = {
  ["6xl"]: "ui-text-6xl ui-leading-3xl",
  ["5xl"]: "ui-text-5xl ui-leading-2xl",
  ["3xl"]: "ui-text-3xl ui-leading-3xl",
  ["2xl"]: "ui-text-2xl ui-leading-2xl",
  ["xl"]: "ui-text-xl ui-leading-xl",
  ["lg"]: "ui-text-lg ui-leading-lg",
  ["md"]: "ui-text-base ui-leading-base",
  ["sm"]: "ui-text-sm ui-leading-sm",
  ["xs"]: "ui-text-xs ui-leading-xs",
  ["caption"]: "ui-text-xs ui-leading-xs",
};

const weightClasses = {
  ["normal"]: "ui-font-normal",
  ["semi"]: "ui-font-semi",
  ["semibold"]: "ui-font-semibold",
  ["bold"]: "ui-font-bold",
};
</script>
