import brStringify_miwV0z8fuG from "/var/www/apps/storefront-app/plugins/brStringify.ts";
import revive_payload_client_YTGlmQWWT5 from "/var/www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/var/www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/var/www/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/var/www/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/apps/storefront-app/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vsfSdkPlugin_SebBgNr6z1 from "/var/www/apps/storefront-app/.nuxt/vsfSdkPlugin.ts";
import sentry_client_H7JebPKzSG from "/var/www/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/apps/storefront-app/.nuxt/sentry-client-config.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/var/www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import brsdk_AbQuB3LVRM from "/var/www/apps/storefront-app/plugins/brsdk.ts";
export default [
  brStringify_miwV0z8fuG,
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  vsfSdkPlugin_SebBgNr6z1,
  sentry_client_H7JebPKzSG,
  sentry_client_config_o34nk2sJbg,
  chunk_reload_client_SeG0EjL5Ec,
  brsdk_AbQuB3LVRM
]