<template>
  <Stack class="w-full max-w-[1520px] m-auto md:px-md my-[56px]" justify="center" align="center" data-fs="notFound">
    <Stack justify="between" class="w-4/5">
      <Stack direction="col" class="w-3/5">
        <Heading size="hero" weight="semi">Oops!</Heading>
        <Heading size="lg" weight="semi">We can’t seem to find the page you’re looking for.</Heading>
        <Text
          >It may be that it’s been moved or is temporarily unavailable. Try these links below to get you back on
          track:</Text
        >
        <Stack direction="col">
          <NuxtLink to="/" data-test-id="errorHomepage">
            <Text class="text-mkm-blue-light cursor-pointer">Homepage</Text>
          </NuxtLink>
          <NuxtLink to="/contact-us" data-test-id="errorContact">
            <Text class="text-mkm-blue-light cursor-pointer">Contact us</Text>
          </NuxtLink>
        </Stack>
      </Stack>
      <NuxtImg src="/error/error.png" class="w-[25%]" />
    </Stack>
  </Stack>
</template>
