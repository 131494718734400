import { initSDK } from "@vue-storefront/sdk"
import sdkConfig from "~/sdk.config"
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  const config = sdkConfig();
  const sdk = initSDK<typeof config>(config);

  return {
    provide: {
      vsf: { sdk }
    }
  }
})
