// connector.ts
import { client } from "./client";
import * as methods from "./methods";
import { Options } from "./types";

export const myIntegrationConnector = (options: Options) => {
  client.defaults.baseURL = options.apiUrl;
  client.defaults.withCredentials = true;
  return methods;
};
