import { Module } from "@vue-storefront/sdk";
import { Options } from "./types";
import { myIntegrationConnector } from "./connector";

export interface MKMApiModule extends Module {
  connector: ReturnType<typeof myIntegrationConnector>;
}

export const mkmApiModule = (options: Options): MKMApiModule => ({
  connector: myIntegrationConnector(options),
  utils: {},
  subscribers: {},
});

export { client } from "./client";
