import { mkmApiModule } from "../../packages/mkm-api/src/sdk";
import { bloomreachDiscoveryModule } from "@vsf-enterprise/bloomreach-discovery-sdk";
import { redisModule } from "@vsf-enterprise/redis-sdk";

import type {
  UnifiedEndpoints,
  CustomCategoryEndpoints,
  CheckoutEndpoints,
  CustomCategoryExtension,
} from "../storefront-middleware/types";

export type InferSdk<TName extends keyof CustomCategoryExtension["extendApiMethods"]> = Awaited<
  ReturnType<CustomCategoryExtension["extendApiMethods"][TName]>
>;

export default defineSdkConfig(({ buildModule, middlewareModule, middlewareUrl, getRequestHeaders }) => ({
  mkmAPI: buildModule(mkmApiModule, {
    apiUrl: middlewareUrl + "/middleware",
  }),

  bcAPI: buildModule(middlewareModule<UnifiedEndpoints & CustomCategoryEndpoints>, {
    apiUrl: middlewareUrl + "/commerce",
    defaultRequestConfig: {
      headers: getRequestHeaders(),
    },
  }),
  checkout: buildModule(middlewareModule<CheckoutEndpoints>, {
    apiUrl: `${middlewareUrl}/commerce/checkout`,
    defaultRequestConfig: {
      headers: getRequestHeaders(),
    },
  }),

  brSearch: buildModule(bloomreachDiscoveryModule, {
    apiUrl: middlewareUrl + "/brd",
  }),
  redis: buildModule(redisModule, {
    ioredis: {
      host: "redis",
      port: 6379,
    },
    enabled: process.env.CACHE_ENABLED === "true" ? true : false,
  }),
}));
