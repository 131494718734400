import validate from "/var/www/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-confirmation-pages": () => import("/var/www/apps/storefront-app/middleware/account-confirmation-pages.ts"),
  "is-authenticated": () => import("/var/www/apps/storefront-app/middleware/is-authenticated.ts"),
  "is-not-authenticated": () => import("/var/www/apps/storefront-app/middleware/is-not-authenticated.ts"),
  "password-rest-token": () => import("/var/www/apps/storefront-app/middleware/password-rest-token.ts")
}