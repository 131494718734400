<template>
  <component
    data-testid="buttoncomponent"
    :is="'button'"
    :size="size"
    :variant="variant"
    :disabled="disabled"
    class="btn ui-rounded-sm ui-font-bold ui-uppercase ui-outline-0 ui-w-fit ui-flex ui-items-center ui-justify-center ui-gap-[10px] disabled:ui-pointer-events-none"
    :class="[
      sizeClasses[size],
      variantClasses[variant],
      size === 'lg' && 'btn-lg',
    ]"
  >
    <slot v-if="$slots.before" name="before" />
    <slot />
    <slot v-if="$slots.after" name="after" />
  </component>
</template>

<script lang="ts" setup>
import type { ButtonProps } from "./types";

withDefaults(defineProps<ButtonProps>(), {
  size: "sm",
  variant: "primary",
});

const sizeClasses = {
  ["sm"]: "ui-text-sm ui-p-button-sm ui-leading-[14px]",
  ["md"]: "ui-text-base ui-p-button-md ui-leading-[14px]",
  ["base"]: "ui-text-md ui-p-button-md ui-leading-[20px]",
  ["lg"]: "ui-text-base md:ui-text-lg ui-p-button-lg ui-leading-[20px]",
};

const variantClasses = {
  ["primary"]:
    "ui-bg-mkm-yellow-default ui-text-mkm-blue-default hover:ui-bg-mkm-yellow-dark enabled:active:ui-bg-mkm-yellow-light enabled:focus:ui-bg-mkm-yellow-light disabled:ui-opacity-30",
  ["secondary"]:
    "ui-bg-mkm-blue-default ui-text-white hover:ui-bg-mkm-blue-dark enabled:active:ui-bg-mkm-blue-light enabled:focus:ui-bg-mkm-blue-light disabled:ui-opacity-30",
  ["neutral"]:
    "ui-bg-grey-default ui-text-charcoal-default hover:ui-bg-grey-dark enabled:active:ui-bg-grey-light enabled:focus:ui-bg-grey-light disabled:ui-opacity-30",
  ["outline"]:
    "ui-bg-white ui-text-mkm-blue-default ui-border-sm ui-border-mkm-blue-default enabled:hover:ui-bg-grey-light enabled:active:ui-bg-grey-lighter enabled:focus:ui-bg-grey-lighter disabled:ui-opacity-20",
  ["ghost"]:
    "ui-bg-transparent ui-text-charcoal-default hover:ui-bg-grey-light enabled:active:ui-bg-grey-lighter enabled:focus:ui-bg-grey-lighter disabled:ui-opacity-20",
  ["destructive"]:
    "ui-bg-red-default ui-text-white hover:ui-bg-red-dark enabled:active:ui-bg-red-light enabled:focus:ui-bg-red-light disabled:ui-opacity-20",
  ["success"]:
    "ui-bg-green-default ui-text-white enabled:hover:ui-bg-green-dark enabled:active:ui-bg-green-light enabled:focus:ui-bg-green-light disabled:ui-opacity-20 disabled:ui-text-green-default disabled:ui-bg-green-lighter",
};
</script>

<style>
.btn svg {
  width: 14px;
  height: 14px;
}

.btn.btn-lg svg {
  width: 20px;
  height: 20px;
}
</style>
