<template>
  <Stack class="w-full my-3xl" justify="center" align="center" data-fs="serverError">
    <Stack direction="col" class="w-full" justify="center" align="center">
      <Heading size="hero" weight="semi" class="text-center">Oops!</Heading>
      <Heading size="lg" weight="semi" class="text-center">This means something has gone wrong...</Heading>
      <Text class="text-center">Don’t worry, we’ll be back up and running in a jiffy.</Text>
      <Button variant="secondary" @click="refreshPage">TRY REFRESHING THE PAGE</Button>
    </Stack>
  </Stack>
</template>
<script setup lang="ts">
const router = useRouter();

const refreshPage = () => {
  router.go(0);
};
</script>
