import { client } from "../client";
import { CreateBrochureRequestParams, CreateBrochureRequestResponse } from "../../types";

export const createBrochureRequest = async (
  params: CreateBrochureRequestParams,
): Promise<CreateBrochureRequestResponse> => {
  const response = await client.post("/createBrochureRequest", { params });

  return response.data;
};
