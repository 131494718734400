<template>
  <component
    :is="tag"
    :size="size"
    :align="align"
    :uppercase="uppercase"
    class="w-full"
    :class="[
      className,
      sizeClasses[size],
      uppercase && 'ui-uppercase',
      align && `ui-text-${align}`,
      displayFont ? 'ui-font-display' : 'ui-font-sans',
      `ui-font-${weight}`,
    ]"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { HeadingProps } from "./types";

withDefaults(defineProps<HeadingProps>(), {
  tag: "h1",
  size: "hero",
  uppercase: false,
  weight: "semibold",
  displayFont: false,
});

const sizeClasses = {
  ["hero"]: "ui-text-5xl ui-leading-5xl md:ui-text-hero md:ui-leading-hero",
  ["xl"]: "ui-text-4xl ui-leading-4xl md:ui-text-7xl md:ui-leading-7xl",
  ["lg"]: "ui-text-3xl ui-leading-3xl md:ui-text-6xl md:ui-leading-6xl",
  ["md"]: "ui-text-2xl ui-leading-2xl md:ui-text-5xl md:ui-leading-5xl",
  ["sm"]: "ui-text-xl ui-leading-xl md:ui-text-4xl md:ui-leading-4xl",
  ["xs"]: "ui-text-md ui-leading-base md:ui-text-2xl md:ui-leading-2xl",
  ["xxs"]: "ui-text-base ui-leading-md md:ui-text-lg md:ui-leading-lg",
};
</script>
