<template>
  <NuxtLayout name="simple">
    <NotFound v-if="error?.statusCode === 404" />
    <ServerError v-else />
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

console.error(props.error);
</script>
